<template>
    <div class="CEESAR-ApplicationTitle">
        <MaterialDesign-Icon v-if="icon != undefined" :icon="icon" size="36" type="Outlined"/>
        <div class="CEESAR-ApplicationTitle-Label">
            {{title}}
        </div>
    </div>
</template>

<script>
export default {
    name: "CEESAR-ApplicationTitle",
    props:{
        title: { type: String, required: true },
        icon: { type: String, default: undefined }
    }
}
</script>

<style>
</style>